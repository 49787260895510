export default [
  {
    path: '/admin/logs',
    name: 'Logs (Admin)',
    component: () => import('@/views/Admin/Reports/Index.vue'),
    meta: {
      resource: 'users',
      action: 'update',
      pageTitle: 'Logs'
    }
  }
]
