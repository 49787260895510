export default [
  {
    path: '/transacoes',
    name: 'Transações',
    component: () => import('@/views/pages/Orders/Index.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read'
    }
  }
]
