export default [
  {
    path: '/admin/users',
    name: 'Usuários (Admin)',
    component: () => import('@/views/Admin/Users/Index.vue'),
    meta: {
      resource: 'users',
      action: 'read',
      pageTitle: 'Usuários',
      breadcrumb: [
        {
          text: 'Usuários',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/users/view/:id',
    name: 'Visualizar Usuário (Admin)',
    component: () => import('@/views/Admin/Users/View/Index.vue'),
    meta: {
      resource: 'users',
      action: 'read',
      pageTitle: 'Visualizar Usuário',
      breadcrumb: [
        {
          text: 'Usuários',
          to: { name: 'Usuários (Admin)' }
        },
        {
          text: 'Visualizar Usuário',
          active: true
        }
      ]
    }
  }
]
