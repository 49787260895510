export default [
  {
    path: '/admin/fornecedores',
    name: 'Fornecedores (Admin)',
    component: () => import('@/views/Admin/Supplier/Index.vue'),
    meta: {
      resource: 'services',
      action: 'read',
      pageTitle: 'Fornecedores',
      breadcrumb: [
        {
          text: 'Fornecedores',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/fornecedores/:id',
    name: 'Serviços do Fornecedor (Admin)',
    component: () => import('@/views/Admin/SupplierService/Index.vue'),
    meta: {
      resource: 'services',
      action: 'read',
      pageTitle: 'Fornecedores',
      breadcrumb: [
        {
          text: 'Fornecedores',
          active: false,
          to: { name: 'Fornecedores (Admin)' }
        },
        {
          text: 'Serviços do Fornecedor (Admin)',
          active: true
        }
      ]
    }
  }
]
