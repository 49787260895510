export default [
  {
    path: '/controle_infinity/assinar',
    name: 'Controle Infinity (Assinar)',
    component: () => import('@/views/pages/ControleInfinity/Purchase/Index.vue'),
    meta: {
      resource: 'controle',
      action: 'buy'
    }
  },
  {
    path: '/controle_infinity/renovar',
    name: 'Controle Infinity (Renovar)',
    component: () => import('@/views/pages/ControleInfinity/Renew/Index.vue'),
    meta: {
      resource: 'controle',
      action: 'all',
      pageTitle: 'Renovar Assinatura',
      breadcrumb: [
        {
          text: 'Minha Assinatura',
          to: { name: 'Controle Infinity (Minha Assinatura)' }
        },
        {
          text: 'Renovar Assinatura',
          active: true
        }
      ]
    }
  },
  {
    path: '/controle_infinity/minha_assinatura',
    name: 'Controle Infinity (Minha Assinatura)',
    component: () => import('@/views/pages/ControleInfinity/MySubscription/Index.vue'),
    meta: {
      resource: 'controle',
      action: 'all',
      pageTitle: 'Resumo',
      breadcrumb: [
        {
          text: 'Resumo',
          active: true
        }
      ]
    }
  },
  {
    path: '/controle_infinity/gerenciar_ganhos',
    name: 'Controle Infinity (Gerenciar Ganhos)',
    component: () => import('@/views/pages/ControleInfinity/ManageEarnings/Index.vue'),
    meta: {
      resource: 'controle',
      action: 'all'
    }
  },
  {
    path: '/controle_infinity/gerenciar_contas',
    name: 'Controle Infinity (Gerenciar Contas)',
    component: () => import('@/views/pages/ControleInfinity/MyAccounts/Index.vue'),
    meta: {
      resource: 'controle',
      action: 'all',
      pageTitle: 'Gerenciar Contas',
      breadcrumb: [
        {
          text: 'Gerenciar Contas',
          active: true
        }
      ]
    }
  },
  {
    path: '/controle_infinity/gerenciar_contas/importar_contas',
    name: 'Controle Infinity (Gerenciar Contas / Importar Contas)',
    component: () => import('@/views/pages/ControleInfinity/ImportAccounts/Index.vue'),
    meta: {
      resource: 'controle',
      action: 'all',
      pageTitle: 'Importar Contas',
      breadcrumb: [
        {
          text: 'Gerenciar Contas',
          to: { name: 'Controle Infinity (Gerenciar Contas)' }
        },
        {
          text: 'Importar Contas',
          active: true
        }
      ]
    }
  },
  {
    path: '/controle_infinity/gerenciar_contas/adicionar_conta',
    name: 'Controle Infinity (Gerenciar Contas / Adicionar Conta)',
    component: () => import('@/views/pages/ControleInfinity/AddAccount/Index.vue'),
    meta: {
      resource: 'controle',
      action: 'all',
      pageTitle: 'Adicionar Conta',
      breadcrumb: [
        {
          text: 'Gerenciar Contas',
          to: { name: 'Controle Infinity (Gerenciar Contas)' }
        },
        {
          text: 'Adicionar nova conta',
          active: true
        }
      ]
    }
  },
  {
    path: '/controle_infinity/gerenciar_contas/atualizar_conta/:id',
    name: 'Controle Infinity (Gerenciar Contas / Atualizar Conta)',
    component: () => import('@/views/pages/ControleInfinity/UpdateAccount/Index.vue'),
    meta: {
      resource: 'controle',
      action: 'all',
      pageTitle: 'Atualizar Conta',
      breadcrumb: [
        {
          text: 'Gerenciar Contas',
          to: { name: 'Controle Infinity (Gerenciar Contas)' }
        },
        {
          text: 'Atualizar conta',
          active: true
        }
      ]
    }
  },
  {
    path: '/controle_infinity/gerenciar_ganhos/adicionar',
    name: 'Controle Infinity (Gerenciar Ganhos / Adicionar)',
    component: () => import('@/views/pages/ControleInfinity/ManageEarnings/Create.vue'),
    meta: {
      resource: 'controle',
      action: 'all',
      pageTitle: 'Adicionar Ganhos',
      breadcrumb: [
        {
          text: 'Gerenciar Ganhos',
          to: { name: 'Controle Infinity (Gerenciar Ganhos)' }
        },
        {
          text: 'Adicionar Ganhos',
          active: true
        }
      ]
    }
  },
  {
    path: '/controle_infinity/gerenciar_ganhos/editar/:id',
    name: 'Controle Infinity (Gerenciar Ganhos / Editar)',
    component: () => import('@/views/pages/ControleInfinity/ManageEarnings/Edit.vue'),
    meta: {
      resource: 'controle',
      action: 'all',
      pageTitle: 'Editar Ganhos',
      breadcrumb: [
        {
          text: 'Gerenciar Ganhos',
          to: { name: 'Controle Infinity (Gerenciar Ganhos)' }
        },
        {
          text: 'Editar Ganhos',
          active: true
        }
      ]
    }
  }
]
