export default [
  {
    path: '/sorteios',
    name: 'Sorteios',
    component: () => import('@/views/pages/Draw/Index.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read',
      pageTitle: 'Sorteios',
      breadcrumb: [
        {
          text: 'Sorteios',
          active: true
        }
      ]
    }
  }
]
