export default [
  {
    path: '/adicionar_saldo',
    name: 'Adicionar Saldo',
    component: () => import('@/views/pages/AddBalance/index.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read',
      pageTitle: 'Adicionar Saldo',
      breadcrumb: [
        {
          text: 'Adicionar Saldo',
          active: true
        }
      ]
    }
  }
]
