export default {
  namespaced: true,
  state: {
    services: [],
    service_selected: {},
    option_selected: {},
    socialNetwork_selected: {}
  },
  getters: {
    isSMS (state) {
      return Object.keys(state.service_selected).length > 0
        && state.service_selected.name === 'SMS'
    },
    isSNActions (state) {
      return Object.keys(state.service_selected).length > 0
        && state.service_selected.name === 'Seguidores e Curtidas'
    },
    isPackOfPhotos (state) {
      return Object.keys(state.service_selected).length > 0
        && state.service_selected.name === 'Pack de fotos'
    }
  },
  mutations: {
    async getServices (state) {
      await this._vm.$http.get('/user/services').then(res => {
        state.services = res.data
      })
    },
    setService (state, payload) {
      state.service_selected = payload
    },
    setOption (state, payload) {
      state.option_selected = payload
    },
    setSocialNetwork (state, payload) {
      state.socialNetwork_selected = payload
    }
  },
  actions: {
    async getServices ({ commit }) {
      await commit('getServices')
    },
    setService ({ commit }, payload) {
      commit('setService', payload)
    },
    setOption ({ commit }, payload) {
      commit('setOption', payload)
    },
    setSocialNetwork ({ commit }, payload) {
      commit('setSocialNetwork', payload)
    },
    setOptionStock ({ commit }, payload) {
      commit('setOptionStock', payload)
    }
  }
}
