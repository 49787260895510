import Vue from 'vue'
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_HOST_API,
  params: {
    token: localStorage.getItem('maintenance_token')
  }
})

Vue.prototype.$http = axiosIns

export default axiosIns
