export default [
  {
    path: '/adicionar_saldo/picpay',
    name: 'Adicionar Saldo (PicPay)',
    component: () => import('@/views/pages/AddBalance/PicPay/PicPay.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read',
      pageTitle: 'PicPay',
      breadcrumb: [
        {
          text: 'Adicionar Saldo',
          to: { name: 'Adicionar Saldo' }
        },
        {
          text: 'PicPay',
          active: true
        }
      ],
      navActiveLink: []
    }
  },
  {
    path: '/adicionar_saldo/picpay/enviar_comprovante',
    name: 'Enviar Comprovante (PicPay)',
    component: () => import('@/views/pages/AddBalance/PicPay/PicPayReceipt.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read',
      pageTitle: 'PicPay',
      breadcrumb: [
        {
          text: 'Adicionar Saldo',
          to: { name: 'Adicionar Saldo' }
        },
        {
          text: 'PicPay',
          to: { name: 'Adicionar Saldo (PicPay)' }
        },
        {
          text: 'Enviar Comprovante',
          active: true
        }
      ],
      navActiveLink: []
    }
  }
]
