export default {
  namespaced: true,
  state: {
    level: {}
  },
  mutations: {
    initDiscountLevel (state) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      state.level = userData.level
    },
    listenDiscountLevel (state) {
      const userID = JSON.parse(localStorage.getItem('userData')).id
      window.Echo.private(`user.${userID}`).listen(
        '.DiscountLevelUpdated',
        res => {
          state.level = res.level
        }
      )
    }
  },
  actions: {
    initDiscountLevel ({ commit }) {
      commit('initDiscountLevel')
    },
    listenDiscountLevel ({ commit }) {
      commit('listenDiscountLevel')
    }
  }
}
