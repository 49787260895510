export default [
  {
    path: '/compras/sms',
    name: 'Números Ativos',
    component: () => import('@/views/pages/MyPurchases/AvailableNumbers/AvailableNumbers.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read'
    }
  },
  {
    path: '/compras/sms_historico',
    name: 'Histórico de SMS',
    component: () => import('@/views/pages/MyPurchases/InactiveNumbers/InactiveNumbers.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read'
    }
  },
  {
    path: '/compras/seguidores',
    name: 'Meus Seguidores',
    component: () => import('@/views/pages/MyPurchases/Followers/Followers.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read'
    }
  },
  {
    path: '/compras/contas',
    name: 'Minhas Contas',
    component: () => import('@/views/pages/MyPurchases/Accounts/Index.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read'
    }
  },
  {
    path: '/compras/vps_e_vpn',
    name: 'Minhas VPS',
    component: () => import('@/views/pages/MyPurchases/VPS/Index.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read'
    }
  },
  {
    path: '/compras/minhas_fotos',
    name: 'Minhas Fotos',
    component: () => import('@/views/pages/MyPurchases/PackOfPhotos/Index.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read'
    }
  }
]
