export default [
  {
    path: '/admin/sorteios',
    name: 'Sorteios (Admin)',
    component: () => import('@/views/Admin/Draws/Index.vue'),
    meta: {
      resource: 'users',
      action: 'update',
      pageTitle: 'Sorteios (Admin)'
    }
  }
]
