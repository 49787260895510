export default [
  {
    path: '/pedido_em_massa',
    name: 'Pedido Em Massa',
    component: () => import('@/views/pages/SNActionBulk/Index.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read',
      pageTitle: 'Pedido Em Massa'
    }
  }
]
