import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import user from './modules/user'
import verticalMenu from './vertical-menu'
import buyServices from './modules/buyServices'
import discountLevel from './modules/discountLevel'
import ControleInfinityMyAccounts from './modules/ControleInfinity/MyAccounts'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    user,
    verticalMenu,
    buyServices,
    discountLevel,
    ControleInfinityMyAccounts
  },
  strict: process.env.DEV
})
