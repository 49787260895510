import reports from './reports'
import dashboard from './dashboard'
import users from './users'
import Banishment from './Banishment'
import BalanceController from './BalanceController'
import ApiBalance from './ApiBalance'
import services from './services'
import serviceOptions from './serviceOptions'
import SNAccount from './SNAccount'
import VPSAccount from './VPSAccount'
import Supplier from './Supplier'
import PackOfPhoto from './PackOfPhoto'
import VerificadorInfinity from './VerificadorInfinity'
import news from './news'
import payments from './Payments'
import Affiliate from './Affiliate'
import draws from './draws'
import picpay from './picpay'

const data = [
  ...reports,
  ...dashboard,
  ...users,
  ...Banishment,
  ...BalanceController,
  ...ApiBalance,
  ...services,
  ...serviceOptions,
  ...SNAccount,
  ...VPSAccount,
  ...Supplier,
  ...PackOfPhoto,
  ...VerificadorInfinity,
  ...news,
  ...payments,
  ...Affiliate,
  ...draws,
  ...picpay
]

export default data
