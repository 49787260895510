export default [
  {
    path: '/admin/verificador_infinity',
    name: 'VerificadorInfinity (Admin)',
    component: () => import('@/views/Admin/VerificadorInfinity/Index.vue'),
    meta: {
      resource: 'verificador_infinity_subscription',
      action: 'read',
      pageTitle: 'VerificadorInfinity',
      breadcrumb: [
        {
          text: 'VerificadorInfinity',
          active: true
        }
      ]
    }
  }
]
