import Echo from 'laravel-echo'
import '@/libs/acl'

window.Pusher = require('pusher-js')

export default {
  namespaced: true,
  state: {
    user: {}
  },
  mutations: {
    async setUser (state, user) {
      state.user = user
    },
    async addAbility (state, payload) {
      const newAbility = payload.split('.')

      state.user.ability.push({
        title: payload,
        subject: newAbility[0],
        action: newAbility[1]
      })

      await this._vm.$ability.update(state.user.ability)
    },
    async removeAbility (state, payload) {
      state.user.ability = state.user.ability
        .filter(item => item.title !== payload)

      await this._vm.$ability.update(state.user.ability)
    },
    async updateLocalStorage (state) {
      localStorage.setItem('userData', JSON.stringify(state.user))
    },
    async updateLaravelEcho () {
      const accessToken = localStorage.getItem('accessToken')

      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: '639582a0a9ab1d84b25c',
        cluster: 'mt1',
        disableStats: true,
        authEndpoint: `${process.env.VUE_APP_HOST_API_CLEAN}broadcasting/auth`,
        auth: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json'
          }
        }
      })
    }
  },
  actions: {
    async setUser ({ commit }, user) {
      await commit('setUser', user)
    },
    async addAbility ({ commit }, ability) {
      commit('addAbility', ability)
    },
    async removeAbility ({ commit }, ability) {
      commit('removeAbility', ability)
    },
    async updateLocalStorage ({ commit }) {
      commit('updateLocalStorage')
    },
    async updateLaravelEcho ({ commit }) {
      await commit('updateLaravelEcho')
    }
  }
}
