export default [
  {
    path: '/admin/contas_vps',
    name: 'Adicionar Contas VPS (Admin)',
    component: () => import('@/views/Admin/VPSAccounts/Index.vue'),
    meta: {
      resource: 'services',
      action: 'read',
      pageTitle: 'Adicionar Contas VPS',
      breadcrumb: [
        {
          text: 'Adicionar Contas VPS',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/buscar_vps',
    name: 'Buscar Contas VPS (Admin)',
    component: () => import('@/views/Admin/VPSAccountFind/Index.vue'),
    meta: {
      resource: 'vps_accounts',
      action: 'read',
      pageTitle: 'Buscar VPS',
      breadcrumb: [
        {
          text: 'Buscar VPS',
          active: true
        }
      ]
    }
  }
]
