export default [
  {
    path: '/admin/servicos/opcoes/:id',
    name: 'Opções de Serviço (Admin)',
    component: () => import('@/views/Admin/ServiceOptions/Index.vue'),
    meta: {
      resource: 'service_options',
      action: 'read',
      pageTitle: 'Opções de Serviço',
      breadcrumb: [
        {
          text: 'Serviços',
          to: { name: 'Serviços (Admin)' }
        },
        {
          text: 'Opções de Serviço',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/servicos/opcoes/:id/sms/:option_id',
    name: 'Opções de SMS (Admin)',
    component: () => import('@/views/Admin/ServiceSMS/Index.vue'),
    meta: {
      resource: 'service_options',
      action: 'read',
      pageTitle: 'Opções de SMS',
      breadcrumb: [
        {
          text: 'Serviços',
          to: { name: 'Serviços (Admin)' }
        },
        {
          text: 'Opções de Serviço',
          to: { name: 'Opções de Serviço (Admin)' }
        },
        {
          text: 'Opções de SMS',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/servicos/opcoes/:id/seguidores/:option_id',
    name: 'Opções de Seguidores (Admin)',
    component: () => import('@/views/Admin/ServiceSNActions/Index.vue'),
    meta: {
      resource: 'service_options',
      action: 'read',
      pageTitle: 'Opções de Seguidores',
      breadcrumb: [
        {
          text: 'Serviços',
          to: { name: 'Serviços (Admin)' }
        },
        {
          text: 'Opções de Serviço',
          to: { name: 'Opções de Serviço (Admin)' }
        },
        {
          text: 'Opções de Seguidores',
          active: true
        }
      ]
    }
  }
]
