export default [
  {
    path: '/admin/pack_de_fotos',
    name: 'Adicionar Pack de Fotos (Admin)',
    component: () => import('@/views/Admin/PackOfPhotos/Index.vue'),
    meta: {
      resource: 'services',
      action: 'read',
      pageTitle: 'Adicionar Pack de Fotos',
      breadcrumb: [
        {
          text: 'Adicionar Pack de Fotos',
          active: true
        }
      ]
    }
  }
]
