export default [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/dashboard/index.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read'
    }
  },
  {
    path: '/emails',
    name: 'Emails',
    component: () => import('@/views/pages/TemporaryEmail/Index.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read'
    }
  },
  {
    path: '/emails/:email',
    name: 'Emails',
    component: () => import('@/views/pages/TemporaryEmail/Custom.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read'
    }
  },
  {
    path: '/conversor_de_contas',
    name: 'Conversor de Contas',
    component: () => import('@/views/AccountConverter/Index.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read'
    }
  }
]
