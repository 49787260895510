import services from './services'
import controle from './controle'
import transactions from './Transactions'
import stock from './stock'
import draw from './draw'
import snActionBulk from './sn_action_bulk'

const data = [
  ...services,
  ...controle,
  ...transactions,
  ...stock,
  ...draw,
  ...snActionBulk
]

export default data
