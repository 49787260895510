import { mapState, mapActions } from 'vuex'

export default {
  async mounted () {
    if (localStorage.getItem('accessToken') === null) {
      return
    }

    await this.loadSequence()
  },
  computed: {
    ...mapState('user', ['user'])
  },
  methods: {
    ...mapActions('user', [
      'setUser',
      'updateLaravelEcho'
    ]),
    ...mapActions('discountLevel', [
      'initDiscountLevel',
      'listenDiscountLevel'
    ]),
    async loadSequence () {
      await this.loadUser()
      return true
    },
    async loadUser () {
      const accessToken = localStorage.getItem('accessToken')
      const userDatax = localStorage.getItem('userData')

      if (accessToken === null) {
        localStorage.removeItem('userData')
        localStorage.removeItem('accessToken')

        this.$router.push({ name: 'auth-login' })
          .then(() => {
            window.location.reload(true)
          })
        return
      }

      if (accessToken === 'undefined' && userDatax === 'undefined') {
        localStorage.removeItem('userData')
        localStorage.removeItem('accessToken')

        this.$router.push({ name: 'auth-login' })
          .then(() => {
            window.location.reload(true)
          })
        return
      }

      if (accessToken === undefined || accessToken === 'undefined' || accessToken === 'null' || accessToken === '') {
        localStorage.removeItem('userData')
        localStorage.removeItem('accessToken')

        this.$router.push({ name: 'auth-login' })
          .then(() => {
            window.location.reload(true)
          })
        return
      }

      await this.$http.get('/auth/user',
        {}, { headers: { Authorization: `Bearer ${accessToken}` } })
        .then(async res => {
          const { userData } = res.data
          await this.setUser(userData)
          await this.updateLaravelEcho()
          await this.initDiscountLevel()
          await this.listenDiscountLevel()
        })
        .catch(() => {
          this.$router.push({ name: 'auth-login' })
        })
        .finally(async () => {
          await this.listenBalance()
        })

      return true
    },
    async listenBalance () {
      // eslint-disable-next-line no-unused-vars
      const promise = new Promise((resolve, reject) => {
        window.Echo.private(`user.${this.user.id}`)
          .listen('.Balance',
            res => {
              const userData = {}
              Object.assign(userData, this.user)
              userData.balance = res.amount
              localStorage.setItem('userData', JSON.stringify(userData))
              this.setUser(userData)
            })
        resolve(true)
      })

      return promise
    }
  }
}
