export default [
  {
    path: '/admin/picpay',
    name: 'PicPay (Admin)',
    component: () => import('@/views/Admin/PicPay/Index.vue'),
    meta: {
      resource: 'services',
      action: 'read'
    }
  }
]
