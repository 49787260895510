export default [
  {
    path: '/admin/saldos_api',
    name: 'Saldos de API (Admin)',
    component: () => import('@/views/Admin/ApiBalance/Index.vue'),
    meta: {
      resource: 'users',
      action: 'update',
      pageTitle: 'Saldos de API'
    }
  }
]
