export default {
  namespaced: true,
  state: {
    loading: true,
    url: {
      baseUrl: '',
      filters: ''
    },
    perPage: 10,
    total: 1,
    accounts: [],
    currentPage: 1,
    fields: [
      {
        key: 'username',
        label: 'Usuário',
        sortable: true
      },
      {
        key: 'password',
        label: 'Senha',
        sortable: true
      },
      {
        key: 'email',
        label: 'E-mail',
        sortable: true
      },
      {
        key: 'email_password',
        label: 'Senha Email',
        sortable: true
      },
      {
        key: 'account_local',
        label: 'Local',
        sortable: true
      },
      {
        key: 'actions',
        label: 'Ações'
      }
    ],
    sort: {
      sortBy: '',
      sortDesc: true
    },
    filter: {
      filterBy: '',
      filterText: '',
      filterStatus: '',
      filterPlatform: '',
      startBlockedAt: '',
      endBlockedAt: ''
    },
    selectionMode: null,
    selected: [],
    selectAll: false,
    reloadingTable: false
  },
  mutations: {
    setLoading (state, loading) {
      state.loading = loading
    },
    setUrl (state, url) {
      state.url = url
    },
    setFields (state, fields) {
      state.fields = fields
    },
    setTotal (state, total) {
      state.total = total
    },
    setPerPage (state, perPage) {
      state.perPage = perPage
    },
    setAccounts (state, accounts) {
      state.accounts = accounts
    },
    setCurrentPage (state, currentPage) {
      state.currentPage = currentPage
    },
    setSort (state, sort) {
      state.sort = sort
    },
    setFilter (state, filter) {
      state.filter = filter
    },
    setSelectionMode (state, selectionMode) {
      state.selectionMode = selectionMode
    },
    setSelected (state, selected) {
      state.selected = selected
    },
    setSelectAll (state, selectAll) {
      state.selectAll = selectAll
    },
    setReloadingTable (state, reloadingTable) {
      state.reloadingTable = reloadingTable
    }
  },
  actions: {
    setLoading ({ commit }, payload) {
      commit('setLoading', payload)
    },
    setUrl ({ commit }, payload) {
      commit('setUrl', payload)
    },
    setFields ({ commit }, payload) {
      commit('setFields', payload)
    },
    setTotal ({ commit }, payload) {
      commit('setTotal', payload)
    },
    setPerPage ({ commit }, payload) {
      commit('setPerPage', payload)
    },
    setAccounts ({ commit }, payload) {
      commit('setAccounts', payload)
    },
    setCurrentPage ({ commit }, payload) {
      commit('setCurrentPage', payload)
    },
    setSort ({ commit }, payload) {
      commit('setSort', payload)
    },
    setFilter ({ commit }, payload) {
      commit('setFilter', payload)
    },
    setSelectionMode ({ commit }, payload) {
      commit('setSelectionMode', payload)
    },
    setSelected ({ commit }, payload) {
      commit('setSelected', payload)
    },
    setSelectAll ({ commit }, payload) {
      commit('setSelectAll', payload)
    },
    setReloadingTable ({ commit }, payload) {
      commit('setReloadingTable', payload)
    }
  }
}
