export default [
  {
    path: '/comprar_servicos',
    name: 'Comprar Serviços',
    component: () => import('@/views/pages/BuyServices/Index.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read',
      pageTitle: 'Comprar Serviços',
      breadcrumb: [
        {
          text: 'Comprar Serviços',
          active: true
        }
      ]
    }
  },
  {
    path: '/comprar_vps_e_vpn',
    name: 'Comprar VPS',
    component: () => import('@/views/pages/BuyVPS/Index.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read'
    }
  },
  {
    path: '/verificadorInfinity',
    name: 'VerificadorInfinity',
    component: () => import('@/views/pages/VerificadorInfinity/Index.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read'
    }
  },
  {
    path: '/afiliados',
    name: 'Afiliados',
    component: () => import('@/views/pages/Affiliates/Index.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read',
      pageTitle: 'Indicações',
      breadcrumb: [
        {
          text: 'Indicações',
          active: true
        }
      ]
    }
  }
]
