export default [
  {
    path: '/adicionar_saldo/pix',
    name: 'Adicionar Saldo (Pix)',
    component: () => import('@/views/pages/AddBalance/Pix/Pix.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read',
      pageTitle: 'Pix',
      breadcrumb: [
        {
          text: 'Adicionar Saldo',
          to: { name: 'Adicionar Saldo' }
        },
        {
          text: 'Pix',
          active: true
        }
      ],
      navActiveLink: []
    }
  },
  {
    path: '/adicionar_saldo/pix/checkout/:value',
    name: 'Mercado Pago Pix (Checkout)',
    component: () => import('@/views/pages/AddBalance/Pix/Checkout.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read',
      pageTitle: 'Pix (Checkout)',
      breadcrumb: [
        {
          text: 'Adicionar Saldo',
          to: { name: 'Adicionar Saldo' }
        },
        {
          text: 'Pix',
          to: { name: 'Adicionar Saldo (Pix)' }
        },
        {
          text: 'Pix (Checkout)',
          active: true
        }
      ],
      navActiveLink: []
    }
  }
]
