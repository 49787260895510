export default [
  {
    path: '/admin/saque_de_afiliados',
    name: 'Afiliados (Saque) (Admin)',
    component: () => import('@/views/Admin/AffiliateWithdrawals/Index.vue'),
    meta: {
      resource: 'services',
      action: 'read'
    }
  }
]
