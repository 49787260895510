export default [
  {
    path: '/adicionar_saldo/mercado_pago',
    name: 'Adicionar Saldo (Mercado Pago)',
    component: () => import('@/views/pages/AddBalance/MercadoPago/MercadoPago.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read',
      pageTitle: 'Mercado Pago',
      breadcrumb: [
        {
          text: 'Adicionar Saldo',
          to: { name: 'Adicionar Saldo' }
        },
        {
          text: 'Mercado Pago',
          active: true
        }
      ],
      navActiveLink: []
    }
  },
  {
    path: '/adicionar_saldo/mercado_pago/checkout/:value',
    name: 'Mercado Pago (Checkout)',
    component: () => import('@/views/pages/AddBalance/MercadoPago/Checkout.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read',
      pageTitle: 'Mercado Pago (Checkout)',
      breadcrumb: [
        {
          text: 'Adicionar Saldo',
          to: { name: 'Adicionar Saldo' }
        },
        {
          text: 'Mercado Pago',
          to: { name: 'Adicionar Saldo (Mercado Pago)' }
        },
        {
          text: 'Mercado Pago (Checkout)',
          active: true
        }
      ],
      navActiveLink: []
    }
  }
]
