export default [
  {
    path: '/suporte',
    name: 'Suporte',
    component: () => import('@/views/pages/SupportAndHelp/support.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read'
    }
  },
  {
    path: '/grupos',
    name: 'Grupos',
    component: () => import('@/views/pages/SupportAndHelp/groups.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read'
    }
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('@/views/pages/SupportAndHelp/faq.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read'
    }
  },
  {
    path: '/tutoriais',
    name: 'Tutoriais',
    component: () => import('@/views/pages/SupportAndHelp/tutoriais.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read'
    }
  },
  {
    path: '/api',
    name: 'API',
    component: () => import('@/views/pages/SupportAndHelp/API.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read'
    }
  }
]
