export default [
  {
    path: '/admin/dashboard',
    name: 'Dashboard (Admin)',
    component: () => import('@/views/Admin/Dashboard/Index.vue'),
    meta: {
      resource: 'users',
      action: 'update',
      pageTitle: 'Dashboard'
    }
  }
]
