export default [
  {
    path: '/admin/controle_de_saldo',
    name: 'Controle de Saldo (Admin)',
    component: () => import('@/views/Admin/BalanceController/Index.vue'),
    meta: {
      resource: 'users',
      action: 'update',
      pageTitle: 'Controle de Saldo'
    }
  }
]
