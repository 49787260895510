export default {
  methods: {
    axiosHandler () {
      this.$http.interceptors.response.use(
        res => res,
        err => {
          if (err.response.status === 503) {
            if (window.location.hash !== '#/manutencao') {
              this.$router.push({ name: 'Maintenance' })
            }
          }

          if (err.response.status === 403 && err.response.data.message === 'You are banned per IP from this site.') {
            if (window.location.hash !== '#/banido_por_ip') {
              this.$router.push({ name: 'Banido por IP' })
            }
          }

          if (err.response.status === 401) {
            localStorage.removeItem('userData')
            localStorage.removeItem('accessToken')

            this.$router.push({ name: 'auth-login' })
              .then(() => {
                window.location.reload(true)
              })
            return
          }

          if (err.response.status === 403 && err.response.data.message === 'You are banned from this site.') {
            if (window.location.hash !== '#/banido') {
              this.$router.push({ name: 'Banido' })
            }
          }

          return {
            status: err.response.status,
            data: err.response.data
          }
        }
      )
    }
  },
  mounted () {
    this.axiosHandler()
  }
}
