export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/banido_por_ip',
    name: 'Banido por IP',
    component: () => import('@/views/pages/PageErrors/IpBanned.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read'
    }
  },
  {
    path: '/banido',
    name: 'Banido',
    component: () => import('@/views/pages/PageErrors/Banned.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read'
    }
  },
  {
    path: '/manutencao',
    name: 'Maintenance',
    component: () => import('@/views/pages/PageErrors/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read'
    }
  },
  {
    path: '/manutencao/set_token/:token',
    name: 'Maintenance (Token)',
    component: () => import('@/views/pages/PageErrors/SetToken.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read'
    }
  },
  {
    path: '/termos',
    name: 'Termos',
    component: () => import('@/views/terms.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
    }
  },
  {
    path: '/servicos',
    name: 'Serviços',
    component: () => import('@/views/Services/Index.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
    }
  },
  {
    path: '/perfil',
    name: 'Perfil',
    component: () => import('@/views/pages/AccountSettings/AccountSettings.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read'
    }
  }
]
