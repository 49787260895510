export default [
  {
    path: '/admin/noticias',
    name: 'Noticias (Admin)',
    component: () => import('@/views/Admin/News/Index.vue'),
    meta: {
      resource: 'services',
      action: 'read',
      pageTitle: 'Notícias',
      breadcrumb: [
        {
          text: 'Notícias',
          active: true
        }
      ]
    }
  }
]
