import adicionarSaldo from './adicionarSaldo'
import MercadoPago from './MercadoPago'
import PicPay from './PicPay'
import Pix from './Pix'

const data = [
  ...adicionarSaldo,
  ...MercadoPago,
  ...PicPay,
  ...Pix
]

export default data
