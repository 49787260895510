export default {
  data: () => ({
    version: localStorage.getItem('_version'),
    latestVersion: null
  }),
  watch: {
    latestVersion (val) {
      if (this.version === null) {
        this.version = val
        localStorage.setItem('_version', this.latestVersion)
      }

      if (val !== this.version) {
        // eslint-disable-next-line no-alert
        this.$swal({
          title: 'Atualização Disponível!',
          text: 'Você está rodando uma versão diferente do servidor, para evitar bugs e instabilidades, por favor clique no botão "Recarregar" a baixo.',
          icon: 'info',
          confirmButtonText: 'Recarregar',
          allowOutsideClick: false,
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        }).then(buttons => (buttons.isConfirmed
          ? this.updateVersion(val)
          : null))
      }
    }
  },
  async mounted () {
    await this.getLatestVersion()

    setInterval(() => {
      this.getLatestVersion()
    }, 120000)
  },
  methods: {
    async getLatestVersion () {
      this.$http.get('/version')
        .then(res => {
          this.latestVersion = res.data
        })
        .catch(err => {
          if (err.response.status === 503) {
            if (window.location.hash !== '#/manutencao') {
              this.$router.push({ name: 'Maintenance' })
            }
          }

          if (err.response.status === 403 && err.response.data.message === 'You are banned from this site.') {
            if (window.location.hash !== '#/banido_por_ip') {
              this.$router.push({ name: 'Banido por IP' })
            }
            // eslint-disable-next-line no-alert
            // alert('Você foi banido do site!')
          }
        })
    },
    async updateVersion (version) {
      localStorage.setItem('_version', version)
      window.location.reload(true)
    }
  }
}
